import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'info_circle_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="info_circle_outline_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 9.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0v-4a.75.75 0 01.75-.75z" fill="currentColor" /><path d="M11 7a1 1 0 11-2 0 1 1 0 012 0z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M3.99 3.99A8.476 8.476 0 0110 1.5c2.347 0 4.473.952 6.01 2.49A8.476 8.476 0 0118.5 10a8.476 8.476 0 01-2.49 6.01A8.476 8.476 0 0110 18.5a8.476 8.476 0 01-6.01-2.49A8.476 8.476 0 011.5 10c0-2.347.952-4.473 2.49-6.01zM10 3a6.976 6.976 0 00-4.95 2.05A6.976 6.976 0 003 10a6.98 6.98 0 002.05 4.95A6.976 6.976 0 0010 17a6.975 6.975 0 004.95-2.05A6.975 6.975 0 0017 10a6.976 6.976 0 00-2.05-4.95A6.976 6.976 0 0010 3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20InfoCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20InfoCircleOutline: FC<Icon20InfoCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20InfoCircleOutline as any).mountIcon = mountIcon;

export default Icon20InfoCircleOutline;
