import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'share_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="share_outline_28"><path clip-rule="evenodd" d="M13.476 6.513c0-.442.485-.651.797-.406l9.532 7.483a.52.52 0 010 .812l-9.532 7.484a.495.495 0 01-.797-.406v-4.485a1 1 0 00-1-1c-3.565 0-6.252 1.449-8.26 3.267.362-2.161 1.153-3.807 2.225-5.046 1.497-1.73 3.637-2.78 6.22-3.264a1 1 0 00.815-.983zm2.032-1.979c-1.654-1.298-4.032-.093-4.032 1.979V9.16c-2.565.61-4.854 1.79-6.548 3.749C3.051 15.079 2 18.093 2 22.05a1 1 0 001.788.616c1.741-2.226 4.175-4.292 7.688-4.625v3.438c0 2.072 2.378 3.277 4.032 1.978l9.532-7.483a2.518 2.518 0 000-3.957z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ShareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ShareOutline: FC<Icon28ShareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ShareOutline as any).mountIcon = mountIcon;

export default Icon28ShareOutline;
